import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AbsencesService } from '@wilson/absences';
import { AuthState } from '@wilson/auth/core';
import {
  AbsenceWithCategory,
  DayTasks,
  PublicationStatus,
  ShiftWithActivitiesWithLocations,
  Stay,
  Task,
  TaskType,
} from '@wilson/interfaces';
import {
  HydrateShiftState,
  ShiftsState,
  ShiftWithSyncStatus,
} from '@wilson/shifts-mobile';
import {
  addDays,
  eachDayOfInterval,
  endOfDay,
  format,
  isBefore,
  isFirstDayOfMonth,
  isSameDay,
  isWithinInterval,
  startOfDay,
} from 'date-fns';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { catchError, map, switchMap, first, filter } from 'rxjs/operators';
import { CalendarNavigationService } from './calendar-navigation.service';
import { TimelineStayGateway } from '@wilson/api/gateway';

interface DateRange {
  startDate: Date;
  endDate: Date;
}

@Injectable()
export class CalendarService {
  private readonly START_CALENDAR_DATE = startOfDay(Date.now());

  private selectedDateSubject = new BehaviorSubject<Date>(
    this.START_CALENDAR_DATE,
  );
  private absenceRequestSubscription!: Subscription;
  private staysRequestSubscription!: Subscription;
  private absencesSubject = new BehaviorSubject<AbsenceWithCategory[]>([]);
  private staysSubject = new BehaviorSubject<Stay[]>([]);
  private currentRangeSubject = new BehaviorSubject<DateRange>({
    startDate: this.START_CALENDAR_DATE,
    endDate: this.createDateSixWeeksFrom(this.START_CALENDAR_DATE),
  });
  private loadingAbsencesSubject = new BehaviorSubject<boolean>(false);
  readonly loadingAbsences$ = this.loadingAbsencesSubject.asObservable();
  private loadingStaysSubject = new BehaviorSubject<boolean>(false);
  readonly loadingStays$ = this.loadingStaysSubject.asObservable();

  readonly selectedDate$ = this.selectedDateSubject.asObservable();
  get selectedDate() {
    return this.selectedDateSubject.value;
  }

  readonly dayTasksStream$: Observable<DayTasks[]> = this.currentRangeSubject
    .asObservable()
    .pipe(
      switchMap((range) => {
        return combineLatest([
          this.store.select(ShiftsState.shiftsInDateRangeSelector),
          this.absencesSubject,
          this.staysSubject,
          this.calendarNavigationService.isCurrentViewTheCalendarTab$,
        ]).pipe(
          map(
            ([
              shiftsInDateRangeFn,
              absences,
              stays,
              isCurrentViewTheCalendarTab,
            ]: [
              (start: Date, end: Date, userId: string) => ShiftWithSyncStatus[],
              AbsenceWithCategory[],
              Stay[],
              boolean,
            ]) => {
              if (!isCurrentViewTheCalendarTab) {
                return null;
              } else {
                const shifts = shiftsInDateRangeFn(
                  range.startDate,
                  range.endDate,
                  this.store.selectSnapshot(AuthState.userId) as string,
                );

                return this.constructTasksFromShiftsStaysAndAbsences(
                  absences,
                  shifts,
                  stays,
                  range,
                );
              }
            },
          ),
          filter((result) => !!result), // Only emit further if we receive a truthy value
        );
      }),
    );

  constructor(
    private absencesService: AbsencesService,
    private timelineStayGateway: TimelineStayGateway,
    private store: Store,
    private calendarNavigationService: CalendarNavigationService,
  ) {
    this.setDate(this.START_CALENDAR_DATE);
  }

  refresh() {
    this.retrieveTasksWithDateRange(
      this.currentRangeSubject.value.startDate,
      this.currentRangeSubject.value.endDate,
    );
  }

  retrieveTasksWithDateRange(startDate: Date, endDate: Date) {
    this.getAbsencesInRange(startDate, endDate);
    this.getStaysInRange(startDate, endDate);
    this.store.dispatch(
      new HydrateShiftState({
        startDate,
        endDate,
      }),
    );
    this.currentRangeSubject.next({
      startDate,
      endDate,
    });
  }

  setDate(date: Date) {
    this.selectedDateSubject.next(startOfDay(date));
  }

  createDayTaskFor(date: Date) {
    return {
      date,
      isFirstOfMonth: isFirstDayOfMonth(date),
      tasks: [],
    };
  }

  resetSelectedDate() {
    this.selectedDateSubject.next(this.START_CALENDAR_DATE);
  }

  createDateSixWeeksFrom(startDate: Date) {
    return addDays(startDate, 41);
  }

  private getAbsencesInRange(startDate: Date, endDate: Date) {
    const userId = this.store.selectSnapshot(AuthState.userId);
    if (userId) {
      this.loadingAbsencesSubject.next(true);

      this.absenceRequestSubscription?.unsubscribe();
      this.absenceRequestSubscription = this.absencesService
        .getByUserInDateRange(userId, startDate, endDate)
        .pipe(
          first(),
          catchError(() => []),
          map((absences) => {
            if (!absences?.length) return [];
            return absences.filter(
              ({ status }) => status !== 'withdrawn' && status !== 'declined',
            );
          }),
        )
        .subscribe({
          next: (result) => this.absencesSubject.next(result),
          complete: () => this.loadingAbsencesSubject.next(false),
        });
    }
  }

  private getStaysInRange(startDate: Date, endDate: Date) {
    const userId = this.store.selectSnapshot(AuthState.userId);
    if (userId) {
      this.loadingStaysSubject.next(true);

      this.staysRequestSubscription?.unsubscribe();
      this.staysRequestSubscription = this.timelineStayGateway
        .getStays({
          startDatetime: startOfDay(startDate).toISOString(),
          endDatetime: endOfDay(endDate).toISOString(),
          userIds: [userId],
        })
        .pipe(
          first(),
          catchError(() => []),
          map((stays) => {
            if (!stays?.length) return [];
            return stays;
          }),
        )
        .subscribe({
          next: (result) => this.staysSubject.next(result),
          complete: () => this.loadingStaysSubject.next(false),
        });
    }
  }

  private filterAbsencesForDay(absences: AbsenceWithCategory[], day: Date) {
    return absences
      .filter(
        (absence) =>
          isWithinInterval(day, {
            start: new Date(absence.absentFrom),
            end: new Date(absence.absentTo),
          }) || isSameDay(day, new Date(absence.absentFrom)),
      )
      .map(
        (absence) =>
          ({
            ...absence,
            taskType: TaskType.Absence,
          } as Task<AbsenceWithCategory>),
      );
  }

  private filterStaysForDay(stays: Stay[], day: Date) {
    return stays
      .filter(
        (stay) =>
          isWithinInterval(day, {
            start: new Date(stay.startDatetime),
            end: new Date(stay.endDatetime),
          }) || isSameDay(day, new Date(stay.startDatetime)),
      )
      .map(
        (stays) =>
          ({
            ...stays,
            taskType: TaskType.Stay,
          } as Task<Stay>),
      );
  }

  private filterShiftsForDay(shifts: ShiftWithSyncStatus[], day: Date) {
    return shifts
      .filter((shift) => shift.startDate === format(day, 'yyyy-MM-dd'))
      .filter(
        (shift) => shift.publicationStatus !== PublicationStatus.NotPublished,
      )
      .map(
        (shift) =>
          ({
            ...shift,
            taskType: TaskType.Shift,
          } as Task<ShiftWithActivitiesWithLocations>),
      );
  }

  private taskCompareFn(taskA: Task, taskB: Task) {
    let startA: string;
    let startB: string;

    if (taskA.taskType === TaskType.Shift) {
      startA = taskA.activities[0].startDatetime;
    } else if (taskA.taskType === TaskType.Absence) {
      startA = taskA.absentFrom;
    } else {
      startA = taskA.startDatetime;
    }

    if (taskB.taskType === TaskType.Shift) {
      startB = taskB.activities[0].startDatetime;
    } else if (taskB.taskType === TaskType.Absence) {
      startB = taskB.absentFrom;
    } else {
      startB = taskB.startDatetime;
    }

    if (isBefore(new Date(startA), new Date(startB))) return -1;
    else return 1;
  }

  constructTasksFromShiftsStaysAndAbsences(
    absences: AbsenceWithCategory[],
    shifts: ShiftWithSyncStatus[],
    stays: Stay[],
    range: DateRange,
  ) {
    const daysTasks: DayTasks[] = [];
    const days = eachDayOfInterval({
      start: range.startDate,
      end: range.endDate,
    });

    for (const day of days) {
      const dayTasks = this.createDayTaskFor(day);
      dayTasks.tasks = [
        ...this.filterAbsencesForDay(absences, day),
        ...this.filterShiftsForDay(shifts, day),
        ...this.filterStaysForDay(stays, day),
      ];

      dayTasks.tasks.sort(this.taskCompareFn);
      daysTasks.push(dayTasks);
    }

    return daysTasks;
  }
}
