import {
  ActivityComment,
  NewStartPauseActivityLiveShiftStateAction,
  ResolvedActivityReport,
  ResolvedActivityWithReports,
  ResolvedShiftWithReports,
  Shift,
} from '@wilson/interfaces';
import { ShiftReport } from '@wilson/interfaces';

export class InitializeLiveShiftState {
  static readonly type = '[Shifts] InitializeLiveShiftState';
}

export class HydrateShiftState {
  static readonly type = '[Shifts] HydrateShiftState';
  constructor(
    public range?: {
      startDate: Date;
      endDate: Date;
    },
  ) {}
}

export class StartLiveShiftState {
  static readonly type = '[Shifts] StartLiveShiftState';
  constructor(
    public activityToStartWith: ResolvedActivityWithReports,
    public startWithPlannedTime: boolean,
  ) {}
}

export class StartNextActivityLiveShiftState {
  static readonly type = '[Shifts] StartNextActivityLiveShiftState';
  constructor(
    public pausedActivity: ResolvedActivityWithReports,
    public currentReportedStart: ResolvedActivityReport,
    public currentActivity: ResolvedActivityWithReports,
    public nextActivity: ResolvedActivityWithReports,
    public dateTime: string,
  ) {}
}

export class NewStartPauseActivityLiveShiftState {
  static readonly type = '[Shifts] NewStartPauseActivityLiveShiftState';
  constructor(
    public currentActivityId: NewStartPauseActivityLiveShiftStateAction['currentActivityId'],
    public pauseActivity: NewStartPauseActivityLiveShiftStateAction['pauseActivity'],
    public duplicatedActivity?: NewStartPauseActivityLiveShiftStateAction['duplicatedActivity'],
  ) {}
}

export class StartPauseActivityLiveShiftState {
  static readonly type = '[Shifts] StartPauseActivityLiveShiftState';
  constructor(
    public currentActivity: ResolvedActivityWithReports,
    public minPauseTime: number,
    public startDateTime: Date,
  ) {}
}

export class EndLiveShiftState {
  static readonly type = '[Shifts] EndLiveShiftState';
  constructor(
    public skipUndoneActivities: boolean,
    public undoneActivities: ResolvedActivityWithReports[],
    public pausedActivity: ResolvedActivityWithReports,
    public currentActivity: ResolvedActivityWithReports,
    public dateTime: string,
  ) {}
}

export class SetSubmitShiftState {
  static readonly type = '[Shifts] SetSubmitShiftState';
  constructor(public shiftReport: ShiftReport) {}
}

export class WithdrawSubmittedShift {
  static readonly type = '[Shifts] WithdrawShift';
  constructor(public shiftId: string) {}
}

export class EditShift {
  static readonly type = '[Shifts] EditShift';
  constructor(
    public shiftId: string,
    public shift: Pick<Shift, 'name' | 'shiftCategoryId' | 'comment'>,
  ) {}
}

export class UpdateShiftFromServer {
  static readonly type = '[Shifts] UpdateShiftFromServer';
  constructor(public shiftFromServer: ResolvedShiftWithReports) {}
}

export class CreateShift {
  static readonly type = '[Shifts] CreateShift';
  constructor(public newShift: ResolvedShiftWithReports) {}
}

export class UpdateLastSeenAtShift {
  static readonly type = '[Shifts] Update LastSeenAt of a shift';
  constructor(public shiftId: string) {}
}
export class UpdateConfirmedAtShift {
  static readonly type = '[Shifts] Update confirmedAt of a shift';
  constructor(public shiftId: string) {}
}
export class UpdateDeclinedAtShift {
  static readonly type = '[Shifts] Update declinedAt of a shift';
  constructor(public shiftId: string) {}
}

export class UpdateDeclineReasonShift {
  static readonly type = '[Shifts] Update declined reason of a shift';
  constructor(public reasonAndShiftId: { shiftId: string; reason: string }) {}
}

export class DeleteShift {
  static readonly type = '[Shifts] DeleteShift';
  constructor(public deleteShiftId: string) {}
}

export class UpdateActivity {
  static readonly type = '[Shifts] UpdateActivity';
  constructor(public activity: ResolvedActivityWithReports) {}
}

export class InsertActivity {
  static readonly type = '[Shifts] InsertActivity';
  constructor(public activity: ResolvedActivityWithReports) {}
}

export class InsertActivityWithoutSync {
  static readonly type = '[Shifts] InsertActivity without sync';
  constructor(public activity: ResolvedActivityWithReports) {}
}

export class DeleteActivity {
  static readonly type = '[Shifts] DeleteActivity';
  constructor(public activity: ResolvedActivityWithReports) {}
}

export class RemoveActivity {
  static readonly type = '[Shifts] Remove Activity';
  constructor(
    public payload: {
      activityId: string;
      shiftId: string;
    },
  ) {}
}

export class CreateActivityComment {
  static readonly type = '[Shifts] CreateActivityComment';
  constructor(
    public shiftId: string,
    public activityId: string,
    public comment: ActivityComment,
  ) {}
}

export class UpdateActivityCommentsSeenBy {
  static readonly type = '[Shifts] UpdateActivityCommentsSeenBy';
  constructor(public shiftId: string, public activityId: string) {}
}

export class SetupSyncForAllUnsychronizedShifts {
  static readonly type = '[Shifts] SetupSyncForAllUnsychronizedShifts';
}

export class ClearShiftsState {
  static readonly type = '[Shifts] ClearShiftsState';
}
