import { Injectable } from '@angular/core';
import { StateOperator } from '@ngxs/store';
import { append, patch, updateItem } from '@ngxs/store/operators';
import { activityReportFactory } from '@wilson/activities/util';
import {
  ActivityReportCategory,
  ActivityReportType,
  OperationStatus,
  ResolvedActivityReport,
  ResolvedActivityWithReports,
} from '@wilson/interfaces';
import { WilsonApp } from '@wilson/interfaces';
import { EndLiveShiftState } from './state/shifts.action';
import { ShiftsStateModel, ShiftWithSyncStatus } from './state/shifts.state';
import {
  sortActivitiesForShift,
  sortReportsForActivity,
} from './utils/custom-operators';

@Injectable()
export class EndLiveShiftStateService {
  patchEndLiveShift(
    action: EndLiveShiftState,
    shift: ShiftWithSyncStatus,
  ): StateOperator<ShiftsStateModel>[] {
    const pausedActivity = action.pausedActivity;
    const currentActivity = action.currentActivity;

    if (!shift.id) {
      return [];
    }

    const stateOperators: StateOperator<ShiftsStateModel>[] = [];

    stateOperators.push(
      patch<ShiftsStateModel>({
        activeShiftId: null, // because we end live shift, shift is not active anymore
        shifts: updateItem<ShiftWithSyncStatus>(
          (s) => s?.id === shift.id,
          patch<ShiftWithSyncStatus>({
            syncedAt: null,
          }),
        ),
      }),
    );

    if (pausedActivity?.id) {
      stateOperators.push(
        patch<ShiftsStateModel>({
          shifts: updateItem<ShiftWithSyncStatus>(
            (s) => s?.id === shift.id,
            patch<ShiftWithSyncStatus>({
              activities: updateItem<ResolvedActivityWithReports>(
                (a) => a?.id === pausedActivity.id,
                patch<ResolvedActivityWithReports>({
                  operationalStatus: OperationStatus.Completed,
                  activityReports: append<ResolvedActivityReport>([
                    activityReportFactory(
                      pausedActivity.id,
                      action.dateTime,
                      pausedActivity.endLocation,
                      pausedActivity.endLocationId,
                      ActivityReportCategory.End,
                      ActivityReportType.IsTimes,
                    ),
                  ]),
                }),
              ),
            }),
          ),
        }),
      );

      stateOperators.push(
        sortReportsForActivity(shift.id, pausedActivity.id as string),
      );
    }

    if (action.undoneActivities.length) {
      stateOperators.push(
        ...this.getOperatorsForUndoneActivities(
          shift.id,
          action.undoneActivities,
          action.skipUndoneActivities,
        ),
      );
    }

    stateOperators.push(
      patch<ShiftsStateModel>({
        shifts: updateItem<ShiftWithSyncStatus>(
          (s) => s?.id === shift.id,
          patch<ShiftWithSyncStatus>({
            activities: updateItem<ResolvedActivityWithReports>(
              (a) => a?.id === currentActivity.id,
              patch<ResolvedActivityWithReports>({
                operationalStatus: OperationStatus.Completed,
                activityReports: append<ResolvedActivityReport>([
                  activityReportFactory(
                    currentActivity.id as string,
                    action.dateTime,
                    currentActivity.endLocation,
                    currentActivity.endLocationId,
                    ActivityReportCategory.End,
                    ActivityReportType.IsTimes,
                  ),
                ]),
              }),
            ),
          }),
        ),
      }),
    );

    stateOperators.push(
      sortReportsForActivity(shift.id, currentActivity.id as string),
    );
    stateOperators.push(sortActivitiesForShift(shift.id));

    return stateOperators;
  }

  private getOperatorsForUndoneActivities(
    shiftId: string,
    undoneActivities: ResolvedActivityWithReports[],
    skipUndoneActivities: boolean,
  ): StateOperator<ShiftsStateModel>[] {
    const operators: StateOperator<ShiftsStateModel>[] = [];

    undoneActivities.forEach((undoneActivity) => {
      const patchPayload = this.getPayloadForUndoneActivities(
        undoneActivity.createdFrom === WilsonApp.Mobile,
        skipUndoneActivities,
      );
      operators.push(
        patch<ShiftsStateModel>({
          shifts: updateItem<ShiftWithSyncStatus>(
            (s) => s?.id === shiftId,
            patch<ShiftWithSyncStatus>({
              activities: updateItem<ResolvedActivityWithReports>(
                (a) => a?.id === undoneActivity.id,
                patch<ResolvedActivityWithReports>(patchPayload),
              ),
            }),
          ),
        }),
      );
    });

    return operators;
  }

  private getPayloadForUndoneActivities(
    createdFromMobile: boolean,
    skipUndoneActivities: boolean,
  ): Partial<ResolvedActivityWithReports> {
    if (skipUndoneActivities) {
      if (createdFromMobile) {
        return {
          deletedAt: new Date().toISOString(),
        };
      } else {
        return {
          operationalStatus: OperationStatus.SkippedByUser,
        };
      }
    } else {
      return {
        operationalStatus: OperationStatus.Completed,
      };
    }
  }
}
