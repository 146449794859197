import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Shift, UserQualificationWithSetting } from '@wilson/interfaces';
import { MasterDataState } from '@wilson/master-data/states';
import { ShiftsState } from '@wilson/shifts-mobile';
import { BehaviorSubject, combineLatest, map, Observable, tap } from 'rxjs';
import { HomeStateService } from './home-state.service';
import {
  InitializeHomeStateQualifications,
  InitializeHomeStateShifts,
} from './home.action';

export const HomeStateName = 'home';

export interface HomeStateModel {
  qualifications: UserQualificationWithSetting[];
  shifts: Shift[];
}

@State<HomeStateModel>({
  name: HomeStateName,
  defaults: {
    qualifications: [],
    shifts: [],
  },
})
@Injectable()
export class HomeState {
  private readonly syncingQualifications$ = new BehaviorSubject(false);

  readonly syncingQualificationsData$ = combineLatest([
    this.mobileMasterDataState.syncingMasterData$,
    this.syncingQualifications$,
  ]).pipe(map((syncing) => syncing.includes(true)));
  readonly syncingShiftsData$ = this.shiftsState.syncingShiftsData$;

  readonly syncing$ = combineLatest([
    this.syncingQualificationsData$,
    this.syncingShiftsData$,
  ]).pipe(map((syncing) => syncing.includes(true)));

  constructor(
    private readonly homeStateService: HomeStateService,
    private readonly mobileMasterDataState: MasterDataState,
    private readonly shiftsState: ShiftsState,
  ) {}

  @Selector()
  static shifts(state: HomeStateModel) {
    return state.shifts || [];
  }
  @Selector()
  static qualifications(state: HomeStateModel) {
    return state.qualifications || [];
  }

  @Selector()
  static isEmpty(state: HomeStateModel) {
    return this.isEmptyShifts(state) && this.isEmptyQualifications(state);
  }
  @Selector()
  static isEmptyShifts(state: HomeStateModel) {
    return !state.shifts?.length;
  }
  @Selector()
  static isEmptyQualifications(state: HomeStateModel) {
    return !state.qualifications?.length;
  }

  @Action(InitializeHomeStateQualifications)
  initializeHomeStateQualifications(
    context: StateContext<HomeStateModel>,
  ): Observable<UserQualificationWithSetting[]> {
    return this.homeStateService.getQualifications().pipe(
      tap({
        subscribe: () => {
          this.syncingQualifications$.next(true);
        },
        next: (qualifications) => {
          this.syncingQualifications$.next(false);
          context.patchState({ qualifications });
        },
      }),
    );
  }

  @Action(InitializeHomeStateShifts)
  initializeHomeStateShifts(context: StateContext<HomeStateModel>) {
    return this.homeStateService.getShifts().pipe(
      tap({
        next: (shifts) => {
          context.patchState({ shifts });
        },
      }),
    );
  }
}
