import { Injectable } from '@angular/core';
import { AbsencesService } from '@wilson/absences';
import { ActivitiesService } from '@wilson/activities';
import { OperativeReportsGateway } from '@wilson/api/gateway';
import {
  AbsenceCategory,
  ActivityCategory,
  LocationCategory,
  OperativeReportsCategory,
  QualificationCategory,
  QualificationSetting,
} from '@wilson/interfaces';
import { LocationsService } from '@wilson/locations';
import { QualificationCategoriesService } from '@wilson/qualification-categories';
import { QualificationSettingsService } from '@wilson/qualification-settings';
import { Observable, catchError, forkJoin, of } from 'rxjs';

export interface MasterData {
  activityCategories: ActivityCategory[];
  absenceCategories: AbsenceCategory[];
  qualificationCategories: QualificationCategory[];
  locationCategories: LocationCategory[];
  operativeReportCategories: OperativeReportsCategory[];
  qualificationCategorySettings: QualificationSetting[];
}

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {
  constructor(
    private readonly activitiesService: ActivitiesService,
    private readonly qualificationCategoriesService: QualificationCategoriesService,
    private readonly absencesService: AbsencesService,
    private readonly locationsService: LocationsService,
    private readonly operativeReportsGateway: OperativeReportsGateway,
    private readonly qualificationSettingsService: QualificationSettingsService,
  ) {}

  public getMasterData(): Observable<MasterData> {
    return forkJoin({
      activityCategories: this.activitiesService
        .getEnabledActivityCategories()
        .pipe(catchError(() => of([]))),
      absenceCategories: this.absencesService
        .getAbsenceCategories()
        .pipe(catchError(() => of([]))),
      qualificationCategories: this.qualificationCategoriesService
        .getQualificationCategories()
        .pipe(catchError(() => of([]))),
      locationCategories: this.locationsService
        .getLocationCategories()
        .pipe(catchError(() => of([]))),
      operativeReportCategories: this.operativeReportsGateway
        .getOperativeReportsCategories()
        .pipe(catchError(() => of([]))),
      qualificationCategorySettings: this.qualificationSettingsService
        .getQualificationSettings()
        .pipe(catchError(() => of([]))),
    });
  }
}
