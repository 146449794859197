import { Injectable } from '@angular/core';
import { patch, updateItem } from '@ngxs/store/operators';
import { ShiftWithSyncStatus, ShiftsStateModel } from './state/shifts.state';

@Injectable({
  providedIn: 'root',
})
export class ShiftNotSyncedOperatorFactory {
  public createShiftNotSyncedOperation(shift: ShiftWithSyncStatus) {
    return patch<ShiftsStateModel>({
      shifts: updateItem<ShiftWithSyncStatus>(
        (s) => s?.id === shift.id,
        patch<ShiftWithSyncStatus>({
          syncedAt: null,
        }),
      ),
    });
  }
}
