import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import './extensions';

if (environment.production) {
  enableProdMode();
}

if (environment.sentry.enabled) {
  Sentry.init(
    {
      dsn: environment.sentry.dsn,
      environment: environment.sentry.environment,
    },
    SentryAngular.init,
  );
}

defineCustomElements(window);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
