import { Injectable } from '@angular/core';
import { StateOperator } from '@ngxs/store';
import { append, patch, updateItem } from '@ngxs/store/operators';
import { activityReportFactory } from '@wilson/activities/util';
import {
  ActivityReportCategory,
  ActivityReportType,
  OperationStatus,
  ResolvedActivityReport,
  ResolvedActivityWithReports,
} from '@wilson/interfaces';
import { StartLiveShiftState } from './state/shifts.action';
import { ShiftsStateModel, ShiftWithSyncStatus } from './state/shifts.state';

@Injectable()
export class StartLiveShiftStateService {
  patchStartLiveShift(action: StartLiveShiftState, shift: ShiftWithSyncStatus) {
    const activityToStartWith = action.activityToStartWith;

    let stateOperators: StateOperator<ShiftsStateModel>[] = [];

    stateOperators = [
      ...stateOperators,
      patch<ShiftsStateModel>({
        shifts: updateItem<ShiftWithSyncStatus>(
          (s) => s?.id === shift.id,
          patch<ShiftWithSyncStatus>({
            syncedAt: null,
          }),
        ),
      }),
    ];

    stateOperators = [
      ...stateOperators,
      patch<ShiftsStateModel>({
        shifts: updateItem<ShiftWithSyncStatus>(
          (s) => s?.id === shift.id,
          patch<ShiftWithSyncStatus>({
            activities: updateItem<ResolvedActivityWithReports>(
              (a) => a?.id === activityToStartWith.id,
              patch<ResolvedActivityWithReports>({
                operationalStatus: OperationStatus.Ongoing,
                activityReports: append<ResolvedActivityReport>([
                  activityReportFactory(
                    activityToStartWith.id as string,
                    action.startWithPlannedTime
                      ? activityToStartWith.startDatetime
                      : new Date().toISOString(),
                    activityToStartWith.startLocation,
                    activityToStartWith.startLocationId,
                    ActivityReportCategory.Start,
                    ActivityReportType.IsTimes,
                  ),
                ]),
              }),
            ),
          }),
        ),
      }),
    ];

    return stateOperators;
  }
}
